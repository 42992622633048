import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Rating } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import allProducts from '../Assets/all_product';

const instagramUrl = "https://www.instagram.com/parachutecloths?igsh=dGoxbHIwZzNvcGRx&utm_source=qr";

const reviews = { href: '#', average: 4, totalCount: 117 };

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Example() {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [mainImage, setMainImage] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const foundProduct = allProducts.find(p => p.id === parseInt(productId));
    setProduct(foundProduct);
  }, [productId]);

  useEffect(() => {
    if (product && product.images && product.images.length > 0 && !mainImage) {
      setMainImage(product.images[0]);
    }
  }, [product, mainImage]);

  if (!product) {
    return <div>Loading...</div>;
  }

  const handleAddToCart = () => {
    navigate('/cart');
  };

  const handleThumbnailClick = (item) => {
    setMainImage(item);
  };

  return (
    <div className="bg-white lg:px-20">
      <div className="pt-6">
        <section className='grid grid-cols-1 lg:grid-cols-2 gap-x-8 gap-y-10 px-4 pt-10'>
          {/* Image gallery */}
          <div className="flex flex-col items-center">
            <div className="overflow-hidden rounded-lg max-w-[30rem] max-h-[35rem]">
              <img
                src={mainImage?.src}
                alt={mainImage?.alt}
                className="h-full w-full object-cover object-center"
              />
            </div>
            <div className="flex flex-wrap space-x-5 justify-center">
              {product.images?.map((item) => (
                <div key={item.src} className="aspect-h-2 aspect-w-3 overflow-hidden rounded-lg max-w-[5rem] max-h-[5rem] mt-4">
                  <img
                    src={item.src}
                    alt={item.alt}
                    className="h-full w-full object-cover object-center"
                    onClick={() => handleThumbnailClick(item)}
                  />
                </div>
              ))}
            </div>
          </div>

          {/* Product info */}
          <div className="lg:col-span-1 max-auto max-w-2xl px-4 pb-16 sm:px-6 lg:max-w-7xl lg:px-8 lg:pb-24 text-left">
            <div className="lg:col-span-2">
              <h1 className="text-xl lg:text-xl font-semibold text-gray-900">{product.name}</h1>
              <h1 className='text-lg lg:text-xl text-grey-900 opacity60 pt-1'>Casual T-Shirts</h1>
            </div>

            {/* Options */}
            <div className="mt-4 lg:row-span-3 lg:mt-0">
              <h2 className="sr-only">Product information</h2>
              <p className="ml-2 text-sm font-medium text-green-600 line-through">Rs. {product.price}</p>
              <p className="text-3xl tracking-tight text-gray-900">
                Rs. {product.discountedPrice}
              </p>

              {/* Reviews */}
              <div className="mt-6">
                <div className='flex items-center space-x-3'>
                  <Rating name="read-only" value={reviews.average} readOnly />
                  <p className='op-50 text-sm'>{reviews.totalCount} Ratings</p>
                  <p className='ml-3 text-sm font-medium text-indigo-600 hover:text-indigo-500'>{reviews.totalCount} Reviews</p>
                </div>
              </div>

              <form className="mt-10">
                <Button href={instagramUrl} variant="contained" sx={{ px: '2rem', py: '1rem', bgcolor: '#C1DCDC' }}>Buy Now</Button>
              </form>
            </div>

            <div className="py-10 lg:col-span-2 lg:col-start-1 lg:border-r lg:border-gray-200 lg:pb-16 lg:pr-8 lg:pt-6">
              {/* Description and details */}
              <div>
                <h3 className="sr-only">Description</h3>
                <div className="space-y-6">
                  <p className="text-base text-gray-900">{product.description}</p>
                </div>
              </div>

              <div className="mt-10">
                <h3 className="text-sm font-medium text-gray-900">Highlights</h3>
                <div className="mt-4">
                  <ul role="list" className="list-disc space-y-2 pl-4 text-sm">
                    {product.highlights?.map((highlight) => (
                      <li key={highlight} className="text-gray-400">
                        <span className="text-gray-600">{highlight}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="mt-10">
                <h2 className="text-sm font-medium text-gray-900">Details</h2>
                <div className="mt-4 space-y-6">
                  <p className="text-sm text-gray-600">{product.details}</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
