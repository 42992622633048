import p1_img from "./product_1.JPG";
import p2_img from "./product_2.jpg";
import p3_img from "./product_3.jpg";

let product = [
  {
    id: 1,
    name: 'Green ',
    price: 2299,
    discountedPrice: 1149,
    rating: 3.9,
    reviewCount: 117,
    href: '#',
    image: p1_img,
    
  },
  {
    id: 2,
    name: 'Cream',
    price: 2299,
    discountedPrice: 1149,
    rating: 3.9,
    reviewCount: 117,
    href: '#',
    image: p2_img,
    
  },
  {
    id: 3,
    
    name: 'White',
    price: 2599,
    discountedPrice: 1299,
    rating: 3.9,
    reviewCount: 117,
    href: '#',
    image: p3_img,
  },
];
export default product;