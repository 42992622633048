  import React from 'react'
  import {  Routes,Route, BrowserRouter } from 'react-router-dom'

  import Navigation from '../customer/components/Navigation/Navigation'
  import HomePage from '../customer/Pages/HomePage'
  import Products from '../customer/Pages/Products'
  import ProductDisplayed from '../customer/components/ProductDisplayed/ProductDisplayed'
  import Contactus from '../customer/components/Contactus/Contactus'
  import Footer from '../customer/components/Footer/Footer'
  import Cart from '../customer/components/Cart/Cart'
  import CheckOut from '../customer/components/CheckOut/CheckOut'
  import Order from '../customer/components/Order/Order'
  import OrderDetails from '../customer/components/Order/OrderDetails'
  import LoginForm from '../customer/Auth/LoginForm'
  import RegisterForm from '../customer/Auth/RegisterForm'

  const CustomerRouter = () => {
    return (

      <div>
      <Navigation />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/products" element={<Products />} />
        <Route path="/contactus" element={<Contactus />} />
        <Route path='/login' element={<LoginForm/>}/>
        <Route path='/register' element={<RegisterForm/>}/>
        <Route path="/products/:productId" element={<ProductDisplayed />} />
         {/*<Route path='/Cart' element={<Cart/>}/>
        <Route path='/CheckOut' element={<CheckOut/>}/>
        <Route path='/account/orders' element={<Order/>}/>
        <Route path='/account/orders/:orderId' element={<OrderDetails/>}/>
       
        
        
        
        */}
      </Routes>
      <Footer />
    </div> 
    )
  }

  export default CustomerRouter



