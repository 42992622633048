import React from 'react';
import t_c from '../Assets/t_c.jpg';

const Hero = () => {
  return (
    <div className="flex flex-col md:flex-row bg-customColor p-8 md:p-20 mt-20 md:mt-10 justify-center border-10 border-white rounded-3xl w-11/12 md:w-10/12 lg:w-9/12 xl:w-8/12 mx-auto">
      <div className="content flex-1 md:p-5 font-semibold text-3xl md:text-5xl font-sans text-center md:text-left">
        <h1 className="text-4xl md:text-5xl lg:text-6xl leading-tight">Buy your <br />own Style!</h1>
        <div className="details flex gap-10 justify-center mt-10 md:justify-start">
          <p className="text-lg">50+ <br /> SKUs</p>
          <hr className="hidden md:block border-gray-400 h-8 my-auto" />
          <p className="text-lg">100+ <br /> Customers</p>
        </div>
      </div>
      <div className="image flex justify-center md:justify-end flex-1 rounded-r-lg bg-white p-0 md:p-8">
        <img className="w-full md:w-96 max-w-full" src={t_c} alt="Hero" />
      </div>
    </div>
  );
};

export default Hero;
