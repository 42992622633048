import { Fragment } from 'react';
import StarIcon from '@mui/icons-material/Star';

function ReviewBox({ name, rating, comment }) {
  return (
    <div className="bg-white mx-auto px-4 py-8 rounded-lg shadow-s mb-8">
      <div className="flex items-center justify-center mb-6">
        <StarIcon className="h-12 w-12 text-yellow-500" />
        <StarIcon className="h-12 w-12 text-yellow-500" />
        <StarIcon className="h-12 w-12 text-yellow-500" />
        <StarIcon className="h-12 w-12 text-yellow-500" />
        <StarIcon className="h-12 w-12 text-yellow-500" />
      </div>
      <h3 className="text-xl font-semibold mb-4 text-center">{name}</h3>
      <p className="text-gray-600 text-center">{comment}</p>
    </div>
  );
}

export default function Reviews() {
  const reviews = [
    { name: 'John Doe', rating: 5, comment: 'Absolutely love the quality and design of their clothing. A must-visit for fashion enthusiasts!' },
    { name: 'Jane Smith', rating: 4, comment: 'Prompt delivery and the product exceeded my expectations. Highly recommend!' },
    { name: 'Alice Johnson', rating: 5, comment: 'Impeccable customer service. They helped me find the perfect outfit for my event.' },
  ];

  return (
    <div className="mb-10">
      <div className="bg-white mx-auto px-4 py-8 rounded-lg shadow-s">
        <h2 className="text-3xl font-bold mb-8 text-center">Customer Reviews</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {reviews.map((review, index) => (
            <ReviewBox key={index} name={review.name} rating={review.rating} comment={review.comment} />
          ))}
        </div>
      </div>
    </div>
  );
}
