import React from "react";
import { FaInstagram } from "react-icons/fa";

const Footer = () => {
  const instagramUrl =
    "https://www.instagram.com/parachutecloths?igsh=dGoxbHIwZzNvcGRx&utm_source=qr";

  return (
    <div className="bg-customColor py-12 md:py-16">
      <div className="container mx-auto px-4 lg:px-12">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-8 justify-center">
          <div className="col mb-6 text-center text-sm md:text-base">
            <h4 className="font-bold text-xl text-gray-800 mb-3">PARACHUTE</h4>
            <p className="text-gray-600">Build your own style!</p>
            <div className="flex justify-center mt-4">
              <a href={instagramUrl} target="_blank" rel="noopener noreferrer">
                <FaInstagram className="icon text-2xl" />
              </a>
            </div>
          </div>

          <div className="col mb-6 text-sm md:text-base">
            {/* Add content here */}
          </div>

          <div className="col mb-6 text-center text-sm md:text-base">
            <h5 className="font-semibold text-lg text-gray-800 mb-4">
              Information
            </h5>
            <ul>
              <li className="mb-2">
                <a href="#" className="text-gray-600 hover:text-blue-500">
                  About
                </a>
              </li>
              <li className="mb-2">
                <a href="#" className="text-gray-600 hover:text-blue-500">
                  Product
                </a>
              </li>
              <li className="mb-2">
                <a href="#" className="text-gray-600 hover:text-blue-500">
                  Blog
                </a>
              </li>
            </ul>
          </div>

          <div className="col mb-6 text-center text-sm md:text-base">
            <h5 className="font-semibold text-lg text-gray-800 mb-4">
              Company
            </h5>
            <ul>
              <li className="mb-2">
                <a href="#" className="text-gray-600 hover:text-blue-500">
                  Community
                </a>
              </li>
              <li className="mb-2">
                <a href="#" className="text-gray-600 hover:text-blue-500">
                  Career
                </a>
              </li>
              <li className="mb-2">
                <a href="#" className="text-gray-600 hover:text-blue-500">
                  Our story
                </a>
              </li>
            </ul>
          </div>

          <div className="col mb-6 text-center text-sm md:text-base">
            <h5 className="font-semibold text-lg text-gray-800 mb-4">
              Contact
            </h5>
            <ul>
              <li className="mb-2">
                <a
                  href="mailto:parachutecloths@gmail.com"
                  className="text-gray-600 hover:text-blue-500"
                >
                  parachutecloths@gmail.com
                </a>
              </li>
            </ul>
          </div>
        </div>
        <p className="text-gray-600 text-center mt-8 text-xs md:text-sm">
          © 2024 all Right Reserved. Term of use PARACHUTE
        </p>
      </div>
    </div>
  );
};

export default Footer;
