
import { Fragment, useState, useRef, useEffect } from 'react';
import { Menu, Transition } from '@headlessui/react';
import profile from '../Assets/profile.png';
import { useNavigate } from 'react-router-dom';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';

export default function ProfileIcon() {
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Track login state
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const handleLogin = () => {
    // Perform login actions
    setIsLoggedIn(true);
    // You may also navigate to a login page
  };

  const handleLogout = () => {
    // Perform logout actions
    setIsLoggedIn(false);
    // You may also navigate to a logout page
  };

  return isLoggedIn ? (
    <div className="relative inline-block" ref={dropdownRef}>
      <PersonOutlineIcon
        className="logo cursor-pointer"
        style={{ width: '30px', height: '30px' }}
        onClick={() => setIsOpen(!isOpen)}
      />
      <Transition
        show={isOpen}
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >

        <Menu
          as="div"
          className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          onClose={() => setIsOpen(false)} // Close dropdown when clicking outside
        >
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => navigate('/profile')}
                  className={`block w-full px-4 py-2 text-sm ${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'}`}
                >
                  Profile
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => navigate('/account/orders')}
                  className={`block w-full px-4 py-2 text-sm ${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'}`}
                >
                  Orders
                </button>
              )}
            </Menu.Item>
            <form method="POST" action="#">
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="submit"
                    className={`block w-full px-4 py-2 text-sm ${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'}`}
                  >
                    Sign out
                  </button>
                )}
              </Menu.Item>
            </form>
          </div>
        </Menu>
      </Transition>
    </div>
  ) : (
    <button
          className="bg-customColor hover:bg-blue-400 text-white font-bold py-2 px-4 rounded"
          onClick={() => navigate('/login')}
        >
          Login
        </button>
  );
}


