import react from "react"
import Trending from "../components/Trending/Trending"
import Categories from "../components/Categories/Categories"
import Hero from "../components/Hero/Hero"
import CustomerReviewsPage from "../components/CustomerReviewsPage/CustomerReviewsPage"
import AboutUs from "../components/AboutUs/AboutUs"


const HomePage = () =>{
    return(
        <div>
            <div>  
                <Hero/>
            </div>
            <div>
                <Trending/>
            </div>
            <div>
                <AboutUs/>
            </div>
            <div>
                <Categories/>
            </div>
            <div>
                <CustomerReviewsPage/>
            </div>
        </div>
    )
}

export default HomePage