import React, { useState } from "react";
import cart_icon from "../Assets/cart_icon.png";
import { useNavigate } from "react-router-dom";
import ProfileIcon from '../ProfileIcon/ProfileIcon';



const Navigation = () => {
  const [activeMenu, setActiveMenu] = useState("Home");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate(); // Initialize the navigate function

  const handleClick = (link, menuItem) => {
    navigate(link); // Use navigate to navigate to the specified link
    setActiveMenu(menuItem); // Set the active menu item
  };
  

  const handleToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="flex justify-between items-center py-4 px-6 md:px-12 lg:px-20">
      <div className="flex items-center gap-4 md:gap-10 text-xl font-semibold">
        <h1>PARACHUTE</h1>
      </div>
      <div className="hidden md:flex items-center justify-center">
        <ul className="flex items-center list-none gap-8 md:gap-20 text-base font-medium">
          <li
            onClick={() => handleClick("/", "Home")}
            className={`cursor-pointer ${
              activeMenu === "Home" ? "border-b-4 border-green-500" : ""
            }`}
          >
            Home
          </li>

          <li
            onClick={() => handleClick("/Products", "Products")}
            className={`cursor-pointer ${
              activeMenu === "Products" ? "border-b-4 border-green-500" : ""
            }`}
          >
            Products
          </li>

          <li
            onClick={() => handleClick("/Contactus", "Contact")}
            className={`cursor-pointer ${
              activeMenu === "Contact" ? "border-b-4 border-green-500" : ""
            }`}
          >
            Contact
          </li>
        </ul>
      </div>
      <div className="flex items-center gap-2 md:gap-10">
        <div className="relative">
          <img
            onClick={() => handleClick("/cart", "Cart")}
            src={cart_icon}
            alt="cart"
            style={{ height: "35px" }}
            className="cursor-pointer"
          />
          {/* onClick={() => handleClick("/cart", "Cart")}*/}
          <div
           
            className="absolute top-0 right-0 -mt-1 -mr-1 w-4 h-4 flex items-center justify-center rounded-full bg-red-500 text-white text-xs cursor-pointer"
          >
            0
          </div>
        </div>
        <ProfileIcon/>
      </div>
      <div className="md:hidden relative">
        <button
          onClick={handleToggleMenu}
          className="text-gray-500 hover:text-gray-600 focus:outline-none focus:text-gray-600"
          aria-label="Toggle menu"
        >
          <svg viewBox="0 0 24 24" className="w-6 h-6 fill-current">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3 5h18v2H3V5zm0 6h18v2H3v-2zm0 6h18v2H3v-2z"
            ></path>
          </svg>
        </button>
        {isMenuOpen && (
          <div className="absolute top-12 right-0 w-40 bg-white shadow-lg rounded-md z-10">
            <ul className="py-2 px-4">
              <li
                className={`text-center cursor-pointer py-2 hover:bg-gray-100 ${
                  activeMenu === "Home" ? "border-b-2 border-green-500" : ""
                }`}
                onClick={() => handleClick("/", "Home")}
              >
                Home
              </li>
              <li
                className={`text-center cursor-pointer py-2 hover:bg-gray-100 ${
                  activeMenu === "Products" ? "border-b-2 border-green-500" : ""
                }`}
                onClick={() => handleClick("/Products", "Products")}
              >
                Products
              </li>
              <li
                className={`text-center cursor-pointer py-2 hover:bg-gray-100 ${
                  activeMenu === "Contact" ? "border-b-2 border-green-500" : ""
                }`}
                onClick={() => handleClick("/Contactus", "Contact")}
              >
                Contact
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navigation;
