import { Fragment } from "react";
import CheckroomIcon from "@mui/icons-material/Checkroom";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import PhoneCallbackIcon from "@mui/icons-material/PhoneCallback";

function AboutUs() {
  return (
    <div className="mb-10">
      <div className="bg-white mx-auto px-4 py-8 rounded-lg shadow-s">
        <h2 className="text-3xl font-bold mb-8 text-center">
          Welcome to Our World
        </h2>
        <p className="text-gray-600 text-center mb-8">
          Join us in celebrating a world of luxury fashion, seamless delivery,
          and unparalleled customer service.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="p-8 rounded-lg bg-gray-100 hover:bg-gray-200 transition-colors duration-300">
            <div className="flex items-center justify-center mb-6">
              <div className="bg-customColor p-3 rounded-full">
                <CheckroomIcon className="h-16 md:h-20 w-16 md:w-20 text-black" />
              </div>
            </div>
            <h3 className="text-xl font-semibold mb-4 text-center">
              Stylish Collections
            </h3>
            <p className="text-gray-600 text-center">
              Explore our curated selection of the latest trends and timeless
              classics.
            </p>
          </div>
          <div className="p-8 rounded-lg bg-gray-100 hover:bg-gray-200 transition-colors duration-300">
            <div className="flex items-center justify-center mb-6">
              <div className="bg-customColor p-3 rounded-full">
                <LocalShippingOutlinedIcon className="h-16 md:h-20 w-16 md:w-20 text-black" />
              </div>
            </div>
            <h3 className="text-xl font-semibold mb-4 text-center">
              Swift & Reliable Delivery
            </h3>
            <p className="text-gray-600 text-center mb-4">
              Enjoy prompt and secure delivery services, ensuring your
              satisfaction every time.
            </p>
          </div>
          <div className="p-8 rounded-lg bg-gray-100 hover:bg-gray-200 transition-colors duration-300">
            <div className="flex items-center justify-center mb-6">
              <div className="bg-customColor p-3 rounded-full">
                <PhoneCallbackIcon className="h-16 md:h-20 w-16 md:w-20 text-black" />
              </div>
            </div>
            <h3 className="text-xl font-semibold mb-4 text-center">
              Dedicated Customer Support
            </h3>
            <p className="text-gray-600 text-center">
              Experience personalized assistance from our team of experts,
              available 24/7 for your convenience.
              <br />
              contact us at :{" "}
              <a
                href="tel:+91958854173"
                className="text-indigo-600 hover:text-indigo-500"
              >
                +91 95885 4173
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
