import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import CustomerRouter from './Routers/CustomerRouter';

function App() {
  return (
      <div className="App">
        <CustomerRouter />
      </div>
  );
}

export default App;
