import { useState } from 'react';
import emailjs from 'emailjs-com';
import { Switch } from '@headlessui/react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function ContactUs() {
  const [agreed, setAgreed] = useState(false);
  const [showTermsPopup, setShowTermsPopup] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Extract form field values
    const formData = new FormData(e.target);
    // Convert agreed to terms to string representation
    formData.append('agreed', agreed ? 'Yes' : 'No');

    // Send form data via EmailJS
    emailjs.sendForm('service_gjc2fht', 'template_w6ujzto', e.target, 'mt5W1kE_YWY9ie6iW')
      .then((result) => {
        console.log('Email sent successfully:', result.text);
        // Display alert upon successful submission
        alert('Feedback submitted successfully!');
        // Redirect to the home page
        window.location.href = '/';
      }, (error) => {
        console.error('Error sending email:', error.text);
        // Handle error, show error message to the user, etc.
      });
  };

  return (
    <div className="bg-customColor px-6 py-16 mb-5 sm:py-20 lg:px-8 container mx-auto rounded-lg shadow-lg">
      <div className="max-w-2xl text-center mx-auto">
        <h2 className="text-3xl font-bold tracking-tight text-black sm:text-4xl">Contact Us</h2>
        <p className="mt-2 text-lg leading-8 text-gray-900">Got any questions or feedback? Reach out to us!</p>
      </div>
      <form onSubmit={handleSubmit} className="mx-auto mt-10 max-w-xl">
        <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
          <div>
            <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-gray-900">First Name</label>
            <input
              type="text"
              name="first-name"
              id="first-name"
              placeholder='Enter your first name'
              autoComplete="given-name"
              className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder-gray-400 focus:ring-2 focus:ring-inset focus:ring-white focus:outline-none sm:text-sm"
            />
          </div>
          <div>
            <label htmlFor="last-name" className="block text-sm font-semibold leading-6 text-gray-900">Last Name</label>
            <input
              type="text"
              name="last-name"
              id="last-name"
              placeholder='Enter your last name'
              autoComplete="family-name"
              className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder-gray-400 focus:ring-2 focus:ring-inset focus:ring-white focus:outline-none sm:text-sm"
            />
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">Email</label>
            <input
              type="email"
              name="email"
              id="email"
              placeholder='Enter your email address'
              autoComplete="email"
              className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder-gray-400 focus:ring-2 focus:ring-inset focus:ring-white focus:outline-none sm:text-sm"
            />
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="message" className="block text-sm font-semibold leading-6 text-gray-900">Message</label>
            <textarea
              name="message"
              id="message"
              placeholder='How can we assist you?'
              rows={4}
              className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder-gray-400 focus:ring-2 focus:ring-inset focus:ring-white focus:outline-none sm:text-sm"
            />
          </div>
          <div className="sm:col-span-2 flex items-center">
            <Switch.Group as="div" className="flex items-center">
              <Switch
                checked={agreed}
                onChange={setAgreed}
                className={classNames(
                  agreed ? 'bg-indigo-600' : 'bg-gray-200',
                  'relative inline-flex flex-shrink-0 h-6 w-12 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-offset-2 focus-visible:ring-offset-customColor focus-visible:ring-2 focus-visible:ring-indigo-600'
                )}
              >
                <span className="sr-only">Agree to terms</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    agreed ? 'translate-x-6' : 'translate-x-0',
                    'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                  )}
                />
              </Switch>
              <label htmlFor="toggle" className="ml-3 text-sm font-medium text-gray-900" onClick={() => setShowTermsPopup(true)}>I agree to the <a href="#" className="text-indigo-600 font-semibold">terms and conditions</a>.</label>
            </Switch.Group>
          </div>
        </div>
        <div className="mt-8">
          <button
            type="submit"
            className="block w-full rounded-md bg-indigo-600 px-4 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-offset-2 focus-visible:ring-offset-customColor focus-visible:outline-none focus-visible:ring-indigo-600 transition duration-200"
          >
            Submit
          </button>
        </div>
      </form>

      {/* Terms and Conditions Pop-up */}
      {showTermsPopup && (
        <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="terms-and-conditions-title" role="dialog" aria-modal="true">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div
              className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10">
                    {/* Heroicon name: outline/exclamation */}
                    <svg className="h-6 w-6 text-indigo-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01" />
                    </svg>
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                      Terms and Conditions
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Your details you've filled will be shared with the concerned organization. Your data will be safe and secure. Agree to the terms and conditions to share your information.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button type="button" onClick={() => setShowTermsPopup(false)} className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm">
                  Got it
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
