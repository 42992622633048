import p1_img from "./product_1.JPG";
import p2_img from "./product_2.jpg";
import p3_img from "./product_3.jpg";
import p11_img from "./product_11.JPG";
import p21_img from "./product_21.jpg";
import p31_img from "./product_31.jpg";
import p12_img from "./product_12.JPG";
import p22_img from "./product_22.jpg";
import p32_img from "./product_32.jpg";
import p13_img from "./product_13.JPG";
import p33_img from "./product_33.JPG";
import p4 from "./p4.JPG";
import p5 from "./p5.JPG";
import p6 from "./p6.JPG";
import p7 from "./p7.JPG";
import p8 from "./p8.JPG";
import p9 from "./p9.JPG";
import p10 from "./p10.JPG";
import p11 from "./p11.JPG";
import p12 from "./p12.JPG";
import p13 from "./p13.JPG";
import p14 from "./p14.JPG";
import p15 from "./p15.JPG";
import p16 from "./p16.JPG";
import p61 from "./p61.JPG";

let products = [
  {
    id: 1,
    name: 'Green ',
    price: 2299,
    discountedPrice: 1149,
    rating: 3.9,
    reviewCount: 117,
    href: '#',
    images: [
      {
        src: p1_img,
      },
      {
        src: p11_img,
      },
      {
        src: p12_img,
      },
      {
        src: p13_img,
      },
    ],
    
  description:
    'Comfortable high quality fabric easy to wash and long lasting',
  highlights: [
    'Hand cut and sewn locally',
    'Dyed with our proprietary colors',
    'Pre-washed & pre-shrunk',
    'Ultra-soft 100% cotton',
  ],
  details:
    'Comfortable high quality fabric easy to wash and long lasting.',
    
    
  },
  {
    id: 2,
    name: 'Cream',
    price: 2299,
    discountedPrice: 1149,
    rating: 3.9,
    reviewCount: 117,
    href: '#',
    images: [
      {
        src: p2_img,
      },
      {
        src: p21_img,
      },
      {
        src: p22_img,
      },
    ],
    
  description:
    'Comfortable high quality fabric easy to wash and long lasting',
  highlights: [
    'Hand cut and sewn locally',
    'Dyed with our proprietary colors',
    'Pre-washed & pre-shrunk',
    'Ultra-soft 100% cotton',
  ],
  details:
    'Comfortable high quality fabric easy to wash and long lasting.',
    
  },
  {
    id: 3,
    
    name: 'White',
    price: 2599,
    discountedPrice: 1299,
    rating: 3.9,
    reviewCount: 117,
    href: '#',
    images: [
      {
        src: p3_img,
      },
      {
        src: p31_img,
      },
      {
        src: p32_img,
      },
      {
        src: p33_img,
      },
    ],
    
  description:
    'Comfortable high quality fabric easy to wash and long lasting',
  highlights: [
    'Hand cut and sewn locally',
    'Dyed with our proprietary colors',
    'Pre-washed & pre-shrunk',
    'Ultra-soft 100% cotton',
  ],
  details:
    'Comfortable high quality fabric easy to wash and long lasting.',
    
  },
  {
    id: 4,
    
    name: 'White',
    price: 2599,
    discountedPrice: 1299,
    rating: 3.9,
    reviewCount: 117,
    href: '#',
    images: [
      {
        src: p4,
      },
    ],
    
  description:
    'Comfortable high quality fabric easy to wash and long lasting',
  highlights: [
    'Hand cut and sewn locally',
    'Dyed with our proprietary colors',
    'Pre-washed & pre-shrunk',
    'Ultra-soft 100% cotton',
  ],
  details:
    'Comfortable high quality fabric easy to wash and long lasting.',
    
  },
  {
    id: 5,
    
    name: 'Unisex printed T-shirt',
    price: 899,
    discountedPrice: 449,
    rating: 3.9,
    reviewCount: 117,
    href: '#',
    images: [
      {
        src: p5,
      },
    ],
    
  description:
    'Comfortable high quality fabric easy to wash and long lasting',
  highlights: [
    'Hand cut and sewn locally',
    'Dyed with our proprietary colors',
    'Pre-washed & pre-shrunk',
    'Ultra-soft 100% cotton',
  ],
  details:
    'Comfortable high quality fabric easy to wash and long lasting.',
    
  },
  {
    id: 6,
    
    name: 'Unisex printed T-shirt',
    price: 899,
    discountedPrice: 449,
    rating: 3.9,
    reviewCount: 117,
    href: '#',
    images: [
      {
        src: p6,
      },
      {
        src: p61,
      },
    ],
    
  description:
    'Comfortable high quality fabric easy to wash and long lasting',
  highlights: [
    'Hand cut and sewn locally',
    'Dyed with our proprietary colors',
    'Pre-washed & pre-shrunk',
    'Ultra-soft 100% cotton',
  ],
  details:
    'Comfortable high quality fabric easy to wash and long lasting.',
    
  },
  {
    id: 7,
    
    name: 'Textured shirt',
    price: 2299,
    discountedPrice: 1149,
    rating: 3.9,
    reviewCount: 117,
    href: '#',
    images: [
      {
        src: p7,
      },
    ],
    
  description:
    'Comfortable high quality fabric easy to wash and long lasting',
  highlights: [
    'Hand cut and sewn locally',
    'Dyed with our proprietary colors',
    'Pre-washed & pre-shrunk',
    'Ultra-soft 100% cotton',
  ],
  details:
    'Comfortable high quality fabric easy to wash and long lasting.',
    
    
  },
  {
    id: 8,
    
    name: 'Tie Dye T-shirt',
    price: 999,
    discountedPrice: 549,
    rating: 3.9,
    reviewCount: 117,
    href: '#',
    images: [
      {
        src: p8,
      },
    ],
    
  description:
    'Comfortable high quality fabric easy to wash and long lasting',
  highlights: [
    'Hand cut and sewn locally',
    'Dyed with our proprietary colors',
    'Pre-washed & pre-shrunk',
    'Ultra-soft 100% cotton',
  ],
  details:
    'Comfortable high quality fabric easy to wash and long lasting.',
    
    
  },
  {
    id: 9,
    
    name: 'Tie Dye T-shirt',
    price: 999,
    discountedPrice: 549,
    rating: 3.9,
    reviewCount: 117,
    href: '#',
    images: [
      {
        src: p9,
      },
    ],
    
  description:
    'Comfortable high quality fabric easy to wash and long lasting',
  highlights: [
    'Hand cut and sewn locally',
    'Dyed with our proprietary colors',
    'Pre-washed & pre-shrunk',
    'Ultra-soft 100% cotton',
  ],
  details:
    'Comfortable high quality fabric easy to wash and long lasting.',
    
    
  },
  {
    id: 10,
    
    name: 'Tie Dye T-shirt',
    price: 999,
    discountedPrice: 549,
    rating: 3.9,
    reviewCount: 117,
    href: '#',
    images: [
      {
        src: p10,
      },
    ],
    
  description:
    'Comfortable high quality fabric easy to wash and long lasting',
  highlights: [
    'Hand cut and sewn locally',
    'Dyed with our proprietary colors',
    'Pre-washed & pre-shrunk',
    'Ultra-soft 100% cotton',
  ],
  details:
    'Comfortable high quality fabric easy to wash and long lasting.',
    
    
  },
  {
    id: 11,
    
    name: 'Regular T-shirt',
    price: 849,
    discountedPrice: 449,
    rating: 3.9,
    reviewCount: 117,
    href: '#',
    images: [
      {
        src: p11,
      },
    ],
    
  description:
    'Comfortable high quality fabric easy to wash and long lasting',
  highlights: [
    'Hand cut and sewn locally',
    'Dyed with our proprietary colors',
    'Pre-washed & pre-shrunk',
    'Ultra-soft 100% cotton',
  ],
  details:
    'Comfortable high quality fabric easy to wash and long lasting.',
    
    
  },
  {
    id: 12,
    
    name: 'Regular T-shirt',
    price: 849,
    discountedPrice: 449,
    rating: 3.9,
    reviewCount: 117,
    href: '#',
    images: [
      {
        src: p12,
      },
    ],
    
  description:
    'Comfortable high quality fabric easy to wash and long lasting',
  highlights: [
    'Hand cut and sewn locally',
    'Dyed with our proprietary colors',
    'Pre-washed & pre-shrunk',
    'Ultra-soft 100% cotton',
  ],
  details:
    'Comfortable high quality fabric easy to wash and long lasting.',
    
    
  },
  {
    id: 13,
    
    name: 'Regular T-shirt',
    price: 849,
    discountedPrice: 449,
    rating: 3.9,
    reviewCount: 117,
    href: '#',
    images: [
      {
        src: p13,
      },
    ],
    
  description:
    'Comfortable high quality fabric easy to wash and long lasting',
  highlights: [
    'Hand cut and sewn locally',
    'Dyed with our proprietary colors',
    'Pre-washed & pre-shrunk',
    'Ultra-soft 100% cotton',
  ],
  details:
    'Comfortable high quality fabric easy to wash and long lasting.',
    
    
  },
  {
    id: 14,
    
    name: 'Regular T-shirt',
    price: 849,
    discountedPrice: 449,
    rating: 3.9,
    reviewCount: 117,
    href: '#',
    images: [
      {
        src: p14,
      },
    ],
    
  description:
    'Comfortable high quality fabric easy to wash and long lasting',
  highlights: [
    'Hand cut and sewn locally',
    'Dyed with our proprietary colors',
    'Pre-washed & pre-shrunk',
    'Ultra-soft 100% cotton',
  ],
  details:
    'Comfortable high quality fabric easy to wash and long lasting.',
    
    
  },
  {
    id: 15,
    
    name: 'Oversized T-shirt',
    price: 999,
    discountedPrice: 459,
    rating: 3.9,
    reviewCount: 117,
    href: '#',
    images: [
      {
        src: p15,
      },
    ],
    
  description:
    'Comfortable high quality fabric easy to wash and long lasting',
  highlights: [
    'Hand cut and sewn locally',
    'Dyed with our proprietary colors',
    'Pre-washed & pre-shrunk',
    'Ultra-soft 100% cotton',
  ],
  details:
    'Comfortable high quality fabric easy to wash and long lasting.',
    
    
  },
  {
    id: 16,
    
    name: 'Regular T-shirt',
    price: 999,
    discountedPrice: 499,
    rating: 3.9,
    reviewCount: 117,
    href: '#',
    images: [
      {
        src: p16,
      },
    ],
    
  description:
    'Comfortable high quality fabric easy to wash and long lasting',
  highlights: [
    'Hand cut and sewn locally',
    'Dyed with our proprietary colors',
    'Pre-washed & pre-shrunk',
    'Ultra-soft 100% cotton',
  ],
  details:
    'Comfortable high quality fabric easy to wash and long lasting.',
    
    
  },
];

export default products;
