import { useNavigate } from 'react-router-dom';
import product from '../Assets/trending_products';

export default function Trending() {
  const navigate = useNavigate(); // Initialize the navigate function

  const handleProductClick = (productId) => {
    navigate(`./Products/${productId}`); // Navigate to the product page with the specified productId
  };

  const trendingProducts = product.slice(0, 3);

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        <h2 className="text-3xl font-bold mb-8 text-center">Trending Products</h2>
        <div className="mt-6 grid grid-cols-3 gap-8">
          {trendingProducts.map((product) => (
            <div key={product.id} className="group relative" onClick={() => handleProductClick(product.id)}>
              <div className="aspect-w-1 aspect-h-1 bg-gray-200 rounded-md overflow-hidden group-hover:opacity-75">
                <img
                  src={product.image}
                  alt={product.imageAlt}
                  className="object-cover object-center w-full h-full"
                />
              </div>
              <div className="mt-4 flex justify-between">
                <div>
                  <h3 className="text-sm text-gray-700">
                    <a href={product.href}>
                      <span aria-hidden="true" className="absolute inset-0" />
                      {product.name}
                    </a>
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">{product.color}</p>
                </div>
                <div>
                  {product.discountedPrice ? (
                    <div className="flex items-center">
                      <p className="text-sm font-medium text-gray-900 line-through">
                        Rs. {product.price}
                      </p>
                      <p className="ml-2 text-sm font-medium text-green-600">
                        Rs. {product.discountedPrice}
                      </p>
                    </div>
                  ) : (
                    <p className="text-sm font-medium text-gray-900">Rs. {product.price}</p>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
